<template>
  <div>
    <pagamento-dados-nivel2-agrupado
      ref="nivel_2_cards"
      :objeto-acao="objetoAcao">
    </pagamento-dados-nivel2-agrupado>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import PagamentoDadosNivel2Agrupado from './pagamento/PagamentoDadosNivel2Agrupado';

export default {
  data() {
    return {
      objetoAcao: {},
    };
  },
  components: {
    PagamentoDadosNivel2Agrupado,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...generateComputed('CTRT_PGTO', [
      'canAccessPage',
    ]),
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
  },
  beforeMount() {
    if (this.$route.params.objetoAcao) {
      this.objetoAcao = this.$route.params.objetoAcao;
    }
  },
  mounted() {
    window.setTimeout(() => this.accessForbidden(), 1E3);
  },
};
</script>
